.enough-item {
  height: 38px;
  line-height: 38px;
  border: 1px solid #cccccc;
  color: #505050;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 485px;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
}
.enough-item .el-input__inner {
    outline: none;
    border-width: 0;
    text-align: center;
    line-height: 38px;
    border: none;
    font-size: 16px;
}
.enough-item .text {
    width: 74px;
    white-space: nowrap;
}
.enough-item .gray {
    background-color: #e9e9e9;
}
.enough-item .number {
    width: 113px;
}
.enough-item .company {
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
}
.enough-item .del,
  .enough-item .add {
    width: 44px;
    text-align: center;
    color: #fff;
}
.enough-item .del:hover,
    .enough-item .add:hover {
      cursor: pointer;
}
.enough-item .del {
    background-color: red;
}
.enough-item .add {
    background-color: #409EFF;
    font-size: 25px;
}
